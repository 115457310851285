import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  TextInput,
  TextArea,
  formModes,
  getFieldLabelRequired,
  AttachmentsInput,
  getFormattedOptions
} from '../../CommonForm'
import {
  IssueTypePicker,
  IssuePriorityPicker,
  FaultReasonPicker
} from '../../FormPickers'
import { CustomerPicker } from '../../FormPickers/CustomerPicker'
import { ContractTypePicker } from '../../FormPickers/ContractTypePicker'
import { useData, useFormPersist } from '../../../hooks'
import { FormButtons } from '../../Common/Ctas'
import {
  formatZipCodeValue,
  getBastKundAttachments,
  getIssueTypesByCustomerTypeId
} from './helpers'
import { fileTypes } from '../../CommonForm/Attachments'
import {
  TwoColumnsTemplate,
  MainColumnTemplate,
  MainColumnHeader,
  MainColumnContent,
  SidebarColumnTemplate,
  SidebarColumnSection,
  SidebarColumnSectionItem
} from '../../Layout/TwoColumnsTemplate'
import { getUrl } from '../../../apiUrls'
import { getActiveItems } from '../../ContractTypes/helpers'
import { sortByParam } from '../../../helpers/lists'
import { AttachmentsListSection } from '../Details/Details'

/** @typedef {import('../types').ExpandedFile} ExpandedFile */
/** @typedef {import('../types').AttachmentInfoUI} AttachmentInfoUI */

const acceptedFileTypes = [
  fileTypes.jpg,
  fileTypes.png,
  fileTypes.txt,
  fileTypes.pdf,
  fileTypes.doc,
  fileTypes.xls,
  fileTypes.ppt,
  fileTypes.bmp,
  fileTypes.one,
  fileTypes.pub,
  fileTypes.vsdx
]

const FormComponent = ({
  formTitle,
  initialValues,
  values,
  apiErrors,
  isSubmitting,
  dirty,
  setValues,
  setErrors,
  resetForm,
  setFieldValue,
  isMainColumnReadOnly = false,
  mode = formModes.create,
  storeKey,
  canEditAttachments,
  sidebarColumnContent,
  customers,
  customersLoading,
  contractTypes,
  contractTypesLoading,
  issueTypes,
  issueTypesLoading
}) => {
  const { t } = useTranslation()
  const { RecoveredDataMessage } = useFormPersist(
    storeKey,
    values,
    initialValues,
    setValues,
    resetForm,
    {
      disablePersistence: mode !== formModes.create,
      message: t('messages.unsavedData'),
      clearButtonText: t('messages.clearForm'),
      omitValues: useMemo(() => ['attachments'], []) // files are not stored correctly in the localStorage
    }
  )

  useEffect(() => {
    if (!!apiErrors) {
      setErrors(apiErrors)
    }
  }, [apiErrors, setErrors])

  const getLabel = (label, required) =>
    getFieldLabelRequired(t(`issues.form.labels.${label}`), required, mode)

  const [contractTypesForCustomer, setContractTypesForCustomer] = useState([])
  const [issueTypesForCustomer, setIssueTypesForCustomer] = useState([])
  const issueTypesRef = useRef(null)

  useEffect(() => {
    if (!!issueTypes.length) {
      issueTypesRef.current = issueTypes
    }
  }, [issueTypes])

  useEffect(() => {
    if (!customers) {
      return
    }

    if (customers.length === 1) {
      setFieldValue('customerId', customers[0].id)
    }
  }, [customers, setFieldValue])

  // filtering contract types options based on selected customer
  useEffect(() => {
    if (!customers || values.customerId === '' || !contractTypes) {
      return
    }

    const selectedCustomer = customers.find(
      item => String(item.id) === String(values.customerId)
    )

    if (!selectedCustomer) {
      return
    }

    if (!selectedCustomer.contractTypeDtos.length) {
      // if there are no contract types assigned to the selected customer, all items from the API are displayed
      setContractTypesForCustomer(contractTypes)
      return
    }

    let contractTypesForPicker = [
      ...getActiveItems(selectedCustomer.contractTypeDtos)
    ]

    if (initialValues.contractTypeId) {
      // when the issue's contract type is no longer assigned to the customer or is inactive, it still needs to be displayed but disabled (edit mode)
      const selectedContractType = contractTypes.find(
        item => String(item.id) === String(initialValues.contractTypeId)
      )

      if (
        selectedContractType &&
        (!selectedContractType.active ||
          !contractTypesForPicker.some(
            item => item.id === selectedContractType.id
          ))
      ) {
        contractTypesForPicker.unshift({
          ...selectedContractType,
          disabled: true
        })
      }
    }

    setContractTypesForCustomer(contractTypesForPicker)
  }, [
    contractTypes,
    customers,
    initialValues.contractTypeId,
    values.customerId
  ])

  // filtering issue types options based on selected customer's type
  useEffect(() => {
    if (!customers || values.customerId === '' || !issueTypesRef.current) {
      return
    }

    const selectedCustomer = customers.find(
      item => String(item.id) === String(values.customerId)
    )

    if (!selectedCustomer) {
      return
    }

    const { defaultContractTypeId } = selectedCustomer

    if (!!defaultContractTypeId && !values.contractTypeId) {
      setFieldValue('contractTypeId', defaultContractTypeId)
    }

    const customerTypeId = selectedCustomer.customerTypeDto?.id
    const activeIssueTypes = getActiveItems(issueTypesRef.current)
    const filteredIssueTypes = getIssueTypesByCustomerTypeId(
      activeIssueTypes,
      customerTypeId
    )
    // if there are no issue types for the selected customer type, display all
    const issueTypesForPicker = !!filteredIssueTypes.length
      ? filteredIssueTypes
      : activeIssueTypes

    // if the issue edit mode is enabled, handle inactive issue types (disabled)
    // similarly as for the contract types in useEffect above

    setIssueTypesForCustomer(issueTypesForPicker)
  }, [customers, values.customerId, setFieldValue, values.contractTypeId])

  const customersSorted = sortByParam(customers, 'asc', 'name')
  const customersOptions = getFormattedOptions(customersSorted)

  const contractTypesForCustomerSorted = sortByParam(
    contractTypesForCustomer,
    'asc',
    'name'
  )
  const formattedContractTypesOptions = getFormattedOptions(
    contractTypesForCustomerSorted,
    {
      additionalPropsFn: item => ({ disabled: !!item.disabled })
    }
  )

  const issueTypesForCustomerSorted = sortByParam(
    issueTypesForCustomer,
    'asc',
    'name'
  )
  const formattedIssueTypesOptions = getFormattedOptions(
    issueTypesForCustomerSorted
  )

  const onCustomerChange = () => {
    setFieldValue('contractTypeId', '')
    setFieldValue('issueTypeId', '')
    setFieldValue('faultReasonId', '')
  }

  /**
   * @param {AttachmentInfoUI[]} acceptedFiles - currently selected files
   * @param {ExpandedFile[]} value - files already uploaded in the system
   * @returns {number} Total number of files from system bastKund.
   */
  const calculateCurrentFilesNumber = (acceptedFiles = [], value = []) => {
    return getBastKundAttachments(value).length + acceptedFiles.length
  }

  const [isFaultReasonRequired, setIsFaultReasonRequired] = useState(false)

  // check if fault reason is required for selected issue type
  useEffect(() => {
    if (!!issueTypesForCustomer.length) {
      const isFaultReasonRequired = issueTypesForCustomer
        .filter(({ faultReasonRequired }) => faultReasonRequired)
        .map(({ id }) => id)
        ?.includes(values.issueTypeId)

      setIsFaultReasonRequired(isFaultReasonRequired)
    }
  }, [values.issueTypeId, issueTypesForCustomer])

  // This field value depends on the selected issue type so it needs to be validated dynamically here.
  const validateFaultReasonPicker = value => {
    if (isFaultReasonRequired && !value) {
      return t('issues.form.validation.faultReason.required')
    }
    return
  }

  return (
    <>
      <Form>
        <TwoColumnsTemplate>
          <MainColumnTemplate>
            <RecoveredDataMessage />

            <MainColumnHeader
              title={formTitle}
              ctas={
                <FormButtons
                  isSubmitting={isSubmitting}
                  submitDisabled={isSubmitting || !dirty}
                  cancelHidden
                  t={t}
                />
              }
            />

            <MainColumnContent>
              <div className='vf-row'>
                <div className='vf-col-md-6'>
                  <CustomerPicker
                    name='customerId'
                    label={getLabel('customerId', true)}
                    disabled={isMainColumnReadOnly}
                    options={customersOptions}
                    loading={customersLoading}
                    onChange={onCustomerChange}
                  />
                </div>

                <div className='vf-col-md-6'>
                  <ContractTypePicker
                    name='contractTypeId'
                    label={getLabel('contractTypeId', true)}
                    disabled={isMainColumnReadOnly}
                    options={formattedContractTypesOptions}
                    loading={contractTypesLoading}
                    readOnly={values.customerId === ''}
                  />
                </div>
              </div>

              <div className='vf-row'>
                <div className='vf-col-md-6'>
                  <IssueTypePicker
                    name='issueTypeId'
                    label={getLabel('issueTypeId', true)}
                    disabled={isMainColumnReadOnly}
                    options={formattedIssueTypesOptions}
                    loading={issueTypesLoading}
                    readOnly={values.customerId === ''}
                    onChange={value => {
                      setFieldValue('faultReasonId', '')
                    }}
                  />
                </div>

                <div className='vf-col-md-6'>
                  <IssuePriorityPicker
                    name='priority'
                    label={getLabel('priority', true)}
                    disabled={isMainColumnReadOnly}
                  />
                </div>
              </div>

              <div className='vf-row'>
                <div className='vf-col-md-6'>
                  <TextInput
                    name='bastRefNo'
                    label={getLabel('bastRefNo')}
                    disabled={isMainColumnReadOnly}
                  />
                </div>
                {isFaultReasonRequired && (
                  <div className='vf-col-md-6'>
                    <FaultReasonPicker
                      name='faultReasonId'
                      label={getLabel('faultReasonId', true)}
                      disabled={isMainColumnReadOnly}
                      loading={issueTypesLoading}
                      readOnly={values.customerId === ''}
                      validate={validateFaultReasonPicker}
                    />
                  </div>
                )}
              </div>

              <div className='vf-intro-in-caps mt-2'>
                {t('issues.form.titles.issueDetails')}
              </div>

              <TextInput
                name='title'
                label={getLabel('title', true)}
                disabled={isMainColumnReadOnly}
              />

              <TextArea
                name='description'
                label={getLabel('description', true)}
                disabled={isMainColumnReadOnly}
                rows={6}
                maxLength={1000}
              />

              <div className='vf-intro-in-caps mt-2'>
                {t('issues.form.titles.locationAddress')}
              </div>
              <div className='vf-row'>
                <div className='vf-col-lg-8'>
                  <TextInput
                    name='street'
                    label={getLabel('street', true)}
                    disabled={isMainColumnReadOnly}
                  />
                </div>

                <div className='vf-col-lg-4'>
                  <TextInput
                    name='number'
                    label={getLabel('number', true)}
                    disabled={isMainColumnReadOnly}
                  />
                </div>
              </div>

              <div className='vf-row'>
                <div className='vf-col-lg-4'>
                  <TextInput
                    name='zip'
                    label={getLabel('zip')}
                    formatValueFn={formatZipCodeValue}
                    disabled={isMainColumnReadOnly}
                  />
                </div>

                <div className='vf-col-lg-8'>
                  <TextInput
                    name='city'
                    label={getLabel('city')}
                    disabled={isMainColumnReadOnly}
                  />
                </div>
              </div>
            </MainColumnContent>
          </MainColumnTemplate>

          <SidebarColumnTemplate>
            {sidebarColumnContent}

            <SidebarColumnSection>
              <AttachmentsListSection attachments={values.attachments} t={t} />

              <SidebarColumnSectionItem
                label=''
                value={
                  <AttachmentsInput
                    name='attachments'
                    acceptedFileTypes={acceptedFileTypes}
                    canUpload={canEditAttachments}
                    calculateCurrentFilesNumber={calculateCurrentFilesNumber}
                    filesListPosition='none'
                  />
                }
              />
            </SidebarColumnSection>
          </SidebarColumnTemplate>
        </TwoColumnsTemplate>
      </Form>
    </>
  )
}

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...props
}) => {
  const customersUrl = getUrl.customers()
  const contractTypesUrl = getUrl.contractTypes()
  const issueTypesUrl = getUrl.issueTypes()

  const { items: customers, loading: customersLoading } = useData(customersUrl)
  const { items: contractTypes, loading: contractTypesLoading } =
    useData(contractTypesUrl)
  const { items: issueTypes, loading: issueTypesLoading } =
    useData(issueTypesUrl)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <FormComponent
          {...formikProps}
          {...props}
          customers={customers || []}
          customersLoading={customersLoading}
          contractTypes={contractTypes || []}
          contractTypesLoading={contractTypesLoading}
          issueTypes={issueTypes || []}
          issueTypesLoading={issueTypesLoading}
        />
      )}
    </Formik>
  )
}

export default FormContainer

import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextInput, formModes } from '../../CommonForm'
import { useFormPersist, useSlidePanelContext } from '../../../hooks'
import { FormButtons } from '../../Common/Ctas'
import { getFieldLabelRequired } from '../../CommonForm/helpers'

const FormComponent = ({
  initialValues,
  values,
  apiErrors,
  dirty,
  isSubmitting,
  setValues,
  setErrors,
  resetForm,
  readOnly = false,
  mode = formModes.create,
  storeKey
}) => {
  const { t } = useTranslation()

  const { RecoveredDataMessage } = useFormPersist(
    storeKey,
    values,
    initialValues,
    setValues,
    resetForm,
    {
      disablePersistence: mode !== formModes.create,
      message: t('messages.unsavedData'),
      clearButtonText: t('messages.clearForm')
    }
  )

  const { closeSlidePanel } = useSlidePanelContext()

  useEffect(() => {
    if (!!apiErrors) {
      setErrors(apiErrors)
    }
  }, [apiErrors, setErrors])

  const getLabel = (label, required = false) =>
    getFieldLabelRequired(t(`faultReason.form.labels.${label}`), required, mode)

  return (
    <>
      <RecoveredDataMessage />

      <Form>
        <TextInput
          name='name'
          label={getLabel('name', true)}
          readOnly={readOnly}
        />

        {!readOnly && (
          <FormButtons
            handleCancel={closeSlidePanel}
            isSubmitting={isSubmitting}
            submitDisabled={isSubmitting || !dirty}
            className='mt-2'
            t={t}
          />
        )}
      </Form>
    </>
  )
}

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...props
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}>
    {formikProps => <FormComponent {...formikProps} {...props} />}
  </Formik>
)

export default FormContainer

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getUrl } from '../../../apiUrls'
import { apiPostWithToken } from '../../../api'
import Form from './Form'
import { formModes } from '../../CommonForm'
import { handleSubmitFactory } from '../../../helpers/forms'
import { getTranslatedMessage } from '../../../helpers/various'
import {
  validationSchema,
  formatFormValuesForApiCreate as formatFormValuesForApi
} from './helpers'
import { useToastContext } from '../../../hooks'
import { clearStoredValues } from '../../../hooks/useFormPersist'
import routesUrls, { urlWithParam } from '../../../routesUrls'
import { toastTypes } from '../../../contexts/ToastContext'

const STORE_KEY = 'issues_create'

const Create = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addToast } = useToastContext()

  const initialValues = {
    bastRefNo: '',
    city: '',
    customerId: '',
    contractTypeId: '',
    description: '',
    number: '',
    priority: '',
    status: 'OPEN',
    street: '',
    title: '',
    issueTypeId: '',
    zip: '',
    attachments: [],
    faultReasonId: ''
  }

  const [apiErrors, setApiErrors] = useState(null)

  const callback = res => {
    if (!!res.error) {
      const message = getTranslatedMessage(res.message, t)
      addToast(message, {
        type: toastTypes.error
      })
      setApiErrors(res.json)
    } else {
      clearStoredValues(STORE_KEY)
      navigate(urlWithParam(routesUrls.issues.details, { id: res.id }))
    }
  }

  const createUrl = getUrl.issueCreate()

  const handleSubmit = handleSubmitFactory(
    createUrl,
    apiPostWithToken,
    callback,
    formatFormValuesForApi
  )

  return (
    <Form
      formTitle={t('titles.issueCreate')}
      initialValues={initialValues}
      validationSchema={() => validationSchema(t, formModes.create)}
      handleSubmit={handleSubmit}
      mode={formModes.create}
      storeKey={STORE_KEY}
      apiErrors={apiErrors}
      canEditAttachments
    />
  )
}

export default Create

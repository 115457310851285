import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import List from '../Layout'
import { CreateBtn, CtasGroup } from './Ctas'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'
import {
  compareNames,
  getActiveItems,
  getInactiveItems
} from '../ContractTypes/helpers'

const getListConfig = t => ({
  gridTemplateColumns: '1fr',
  attributes: [
    {
      label: t('faultReason.list.headers.name'),
      propName: 'name',
      sortable: false
    }
  ]
})

const ListContainer = () => {
  const { t } = useTranslation()
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  const url = getUrl.faultReasons()
  const { loading, items, error } = useData(url, refresh)

  const Info = getNotification(loading, error, items, Loader)

  const sortedItems = items?.slice().sort(compareNames)

  const activeItems = getActiveItems(sortedItems)

  const inactiveItems = getInactiveItems(sortedItems)

  return (
    <>
      <div className='mb-2 vf-text--right'>
        <CreateBtn refreshList={toggleRefresh} />
      </div>

      {!!Info ? (
        Info
      ) : (
        <List
          items={activeItems}
          listConfig={getListConfig(t)}
          listTitle={`${t('common.labels.active')} (${activeItems.length})`}
          ctas={CtasGroup}
          refreshList={toggleRefresh}
        />
      )}

      {!!inactiveItems.length && (
        <div className='mt-2'>
          <List
            items={inactiveItems}
            listConfig={getListConfig(t)}
            listTitle={`${t('common.labels.inactive')} (${
              inactiveItems.length
            })`}
          />
        </div>
      )}
    </>
  )
}

export default ListContainer

// @ts-check
import { getEnvVariable } from './.env.custom'

const apiRootUrl = getEnvVariable('REACT_APP_API_ROOT_URL')

const customers = {
  customers: 'customer/',
  customerById: 'customer/{keyParam}/',
  customerCreate: 'customer/',
  customerUpdate: 'customer/',
  customerDelete: 'customer/{keyParam}/'
}

const contractTypes = {
  contractTypes: 'contractType/',
  contractTypeById: 'contractType/{keyParam}/',
  contractTypeCreate: 'contractType/',
  contractTypeUpdate: 'contractType/',
  contractTypeInactivate: 'contractType/'
}

const customerTypes = {
  customerTypes: 'customerType/',
  customerTypeById: 'customerType/{keyParam}/',
  customerTypeCreate: 'customerType/',
  customerTypeUpdate: 'customerType/'
}

const faultReasons = {
  faultReasons: 'faultReason/',
  faultReasonById: 'faultReason/{keyParam}/',
  faultReasonCreate: 'faultReason/',
  faultReasonUpdate: 'faultReason/',
  faultReasonDelete: 'faultReason/{keyParam}/'
}

const issueTypes = {
  issueTypes: 'issueType/',
  issueTypeById: 'issueType/{keyParam}/',
  issueTypeCreate: 'issueType/',
  issueTypeUpdate: 'issueType/'
}

const users = {
  users: 'user/',
  userById: 'user/{keyParam}/',
  userCreate: 'user/',
  userUpdate: 'user/',
  userDelete: 'user/{keyParam}/',
  userSearch: 'user/msgraph/',
  userCurrent: 'user/me/',
  magicLink: 'auth/invitation/?email={keyParam}'
}

const issues = {
  issues: 'issue/',
  issueById: 'issue/{keyParam}/',
  issueCreate: 'issue/',
  issueUpdate: 'issue/'
}

const attachments = {
  attachmentById: 'attachment/{keyParam}/',
  attachmentCreate: 'attachment/',
  attachmentUpdate: 'attachment/',
  attachmentDelete: 'attachment/{keyParam}/',
  attachmentByIssue: 'attachment/issue/{keyParam}/'
}

const comments = {
  commentCreate: 'comment/',
  commentById: 'comment/{keyParam}/',
  commentsByIssueId: 'comment/issue/{keyParam}/'
}

const auth = {
  authentication: 'auth/authentication/{keyParam}/',
  invitation: 'auth/invitation/'
}

const charts = {
  chartType: 'charts/type/',
  chartStatus: 'charts/status/',
  chartPriority: 'charts/priority/'
}

const other = {
  time: 'time/'
}

export const apiUrls = {
  ...customers,
  ...customerTypes,
  ...contractTypes,
  ...issueTypes,
  ...faultReasons,
  ...users,
  ...issues,
  ...attachments,
  ...comments,
  ...auth,
  ...charts,
  ...other
}

/**
 * @param {Object} params
 * @returns {string}
 */
const mapObjectToUrlParams = params => {
  const strParams = Object.keys(params)
    .filter(
      key => !!key && (params[key] || params[key] === 0 || params[key] === '')
    )
    .map(key => `${key}=${encodeURI(params[key])}`)
    .join('&')

  return strParams
}

/**
 * @param {string} key
 * @returns {Function}
 */
const getUrlByKey =
  key =>
  /**
   * @param {string} keyParam
   * @param {Object} params
   * @returns {string} url
   */
  (keyParam = '', params = null) => {
    const paramsString = !!params ? `?${mapObjectToUrlParams(params)}` : ''
    const url = `${apiRootUrl}${apiUrls[key].replace(
      '{keyParam}',
      keyParam
    )}${paramsString}`

    return url
  }

export const getUrl = {
  time: getUrlByKey('time'),
  customers: getUrlByKey('customers'),
  customerById: getUrlByKey('customerById'),
  customerCreate: getUrlByKey('customerCreate'),
  customerUpdate: getUrlByKey('customerUpdate'),
  customerDelete: getUrlByKey('customerDelete'),
  customerTypes: getUrlByKey('customerTypes'),
  customerTypeById: getUrlByKey('customerTypeById'),
  customerTypeCreate: getUrlByKey('customerTypeCreate'),
  customerTypeUpdate: getUrlByKey('customerTypeUpdate'),
  contractTypes: getUrlByKey('contractTypes'),
  contractTypeCreate: getUrlByKey('contractTypeCreate'),
  contractTypeById: getUrlByKey('contractTypeById'),
  contractTypeUpdate: getUrlByKey('contractTypeById'),
  contractTypeInactivate: getUrlByKey('contractTypeInactivate'),
  users: getUrlByKey('users'),
  userById: getUrlByKey('userById'),
  userCreate: getUrlByKey('userCreate'),
  userUpdate: getUrlByKey('userUpdate'),
  userDelete: getUrlByKey('userDelete'),
  userSearch: getUrlByKey('userSearch'),
  userCurrent: getUrlByKey('userCurrent'),
  issues: getUrlByKey('issues'),
  issueById: getUrlByKey('issueById'),
  issueCreate: getUrlByKey('issueCreate'),
  issueUpdate: getUrlByKey('issueUpdate'),
  issueTypes: getUrlByKey('issueTypes'),
  faultReasons: getUrlByKey('faultReasons'),
  faultReasonCreate: getUrlByKey('faultReasonCreate'),
  faultReasonDelete: getUrlByKey('faultReasonDelete'),
  faultReasonById: getUrlByKey('faultReasonById'),
  faultReasonUpdate: getUrlByKey('faultReasonUpdate'),
  issueTypeById: getUrlByKey('issueTypeById'),
  issueTypeCreate: getUrlByKey('issueTypeCreate'),
  issueTypeUpdate: getUrlByKey('issueTypeUpdate'),
  attachmentById: getUrlByKey('attachmentById'),
  attachmentCreate: getUrlByKey('attachmentCreate'),
  attachmentUpdate: getUrlByKey('attachmentUpdate'),
  attachmentDelete: getUrlByKey('attachmentDelete'),
  attachmentByIssue: getUrlByKey('attachmentByIssue'),
  commentCreate: getUrlByKey('commentCreate'),
  commentById: getUrlByKey('commentById'),
  commentsByIssueId: getUrlByKey('commentsByIssueId'),
  authAuthentication: getUrlByKey('authentication'),
  authInvitation: getUrlByKey('invitation'),
  magicLink: getUrlByKey('magicLink'),
  chartType: getUrlByKey('chartType'),
  chartStatus: getUrlByKey('chartStatus'),
  chartPriority: getUrlByKey('chartPriority')
}

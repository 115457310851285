import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Form from './Form'
import { formatItemForForm, validationSchema } from './helpers'
import { formModes } from '../../CommonForm'
import {
  handleSubmitFactory,
  callback,
  handleApiErrors
} from '../../../helpers/forms'

import { getUrl } from '../../../apiUrls'
import { apiPutWithToken } from '../../../api'
import { useData, useSlidePanelContext, useToastContext } from '../../../hooks'
import getNotification from '../../../helpers/apiNotifications'
import Loader from '../../Common/Loader'

const Edit = ({ id, refreshList = () => {} }) => {
  const { t } = useTranslation()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const url = getUrl.faultReasonById(id)
  const { loading, items: data, error } = useData(url)

  const initialValues = formatItemForForm(data)

  const [apiErrors, setApiErrors] = useState(null)

  const successCallback = () => {
    refreshList()
    closeSlidePanel()
  }

  const updateUrl = getUrl.faultReasonUpdate()

  const handleSubmit = handleSubmitFactory(
    updateUrl,
    apiPutWithToken,
    callback(addToast, successCallback, handleApiErrors(setApiErrors))
  )

  const Info = getNotification(loading, error, data, Loader)

  return Info ? (
    Info
  ) : (
    <Form
      initialValues={initialValues}
      validationSchema={() => validationSchema(t)}
      handleSubmit={handleSubmit}
      mode={formModes.edit}
      apiErrors={apiErrors}
    />
  )
}

export default Edit

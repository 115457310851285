import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PageTitle from '../../components/Template/PageTitle'
import { Card, CardImage, CardContent } from '../../components/Common/Card'
import routesUrls from '../../routesUrls'
import { ReactComponent as UserIcon } from './icons/VF_user_rgb.svg'
import { ReactComponent as CustomerIcon } from './icons/VF_city_rgb.svg'
import { ReactComponent as CustomerTypesIcon } from './icons/VF_modules_rgb.svg'
import { ReactComponent as ContractTypesIcon } from './icons/VF_policies_rgb.svg'
import { ReactComponent as IssueTypesIcon } from './icons/VF_employee_hard_hat_rgb.svg'
import { ReactComponent as FaultReasonsIcon } from './icons/VF_security_risk_rgb.svg'

import './admin.scss'

const Page = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle>{t('titles.admin')}</PageTitle>

      <div className='admin__links'>
        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.users}>
            <CardImage>
              <UserIcon />
            </CardImage>

            <CardContent title={t('titles.users')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.customers}>
            <CardImage>
              <CustomerIcon />
            </CardImage>

            <CardContent title={t('titles.customers')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.customerTypes}>
            <CardImage>
              <CustomerTypesIcon />
            </CardImage>

            <CardContent title={t('titles.customerTypes')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.contractTypes}>
            <CardImage>
              <ContractTypesIcon />
            </CardImage>

            <CardContent title={t('titles.contractTypes')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.issueTypes}>
            <CardImage>
              <IssueTypesIcon />
            </CardImage>

            <CardContent title={t('titles.issueTypes')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to={routesUrls.admin.faultReasons}>
            <CardImage>
              <FaultReasonsIcon />
            </CardImage>

            <CardContent title={t('titles.faultReasons')} />
          </Link>
        </Card>
      </div>
    </>
  )
}

export default Page

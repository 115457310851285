const auth = {
  login: '/login',
  logout: '/logout'
}

const issues = {
  list: '/issues',
  create: '/issues/create',
  details: '/issues/:id',
  edit: '/issues/:id/edit'
}

const admin = {
  list: '/admin',
  users: '/admin/users',
  customers: '/admin/customers',
  customerTypes: '/admin/customerTypes',
  contractTypes: '/admin/contractTypes',
  issueTypes: '/admin/issueTypes',
  faultReasons: '/admin/faultReasons'
}

const users = {
  details: '/users/:id'
}

const routesUrls = {
  auth,
  issues,
  users,
  admin
  // help: '/help'
}

// #region Exports and Helpers

export default routesUrls

export const urlWithParam = (url, params) => {
  const paramsNames = Object.keys(params)

  paramsNames.forEach(paramName => {
    url = url.replace(`:${paramName}`, params[paramName])
  })

  return url
}

// #endregion

// @ts-check
import * as Yup from 'yup'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .max(255, t('common.form.validation.maxLength', { count: 255 }))
      .required(t('faultReason.form.validation.name.required'))
  })

/**
 * @typedef {import('./types').FaultReasonDto} FaultReasonDto
 * @typedef {import('./types').FaultReasonUI} FaultReasonUI
 */

/**
 * @param {FaultReasonDto} item - item from api to be formatted
 * @returns {FaultReasonUI} ui item
 */
export const formatItemForForm = item => ({
  id: item?.id,
  name: item?.name,
  active: item?.active
})

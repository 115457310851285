// @ts-check
import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUrl } from '../../../apiUrls'
import { useData } from '../../../hooks'
import getNotification from '../../../helpers/apiNotifications'
import {
  getCanEditIssue,
  getCanAddNewComment,
  formatAttachmentsForUi,
  formatZipCodeValue,
  getBastKundAttachments,
  getBastAttachments,
  systems
} from '../Form/helpers'
import { ChangeStatusBtn, EditBtn } from '../Ctas'
import { buttonSizes } from '../../Common/Ctas'
import Loader from '../../Common/Loader'
import {
  TwoColumnsTemplate,
  MainColumnTemplate,
  MainColumnHeader,
  MainColumnContent,
  MainColumnContentItem,
  SidebarColumnTemplate,
  SidebarColumnSection,
  SidebarColumnSectionItem
} from '../../Layout/TwoColumnsTemplate'
import { StatusBadge, PriorityBadge } from './IssueBadges'
import TabBar from '../../DesignSystem/TabBar'
import Comments from './Comments'
import { List as History } from './History'
import { AttachmentsList } from '../../CommonForm/Attachments'
import { getFormattedDateWithTime } from '../../../helpers/dates'
import { getAttachmentsLabel } from '../helpers'
import { getCreateDate, getPriority } from './helpers'

import './details.scss'

/** @typedef {import('../../../types').IssueDto} IssueDto */
/** @typedef {import('i18next').TFunction}  TFunction */
/** @typedef {import('../types').AttachmentInfoUI} AttachmentInfoUI */

const DetailsCtas = ({ item, canEdit = false, refreshItem = () => {} }) => (
  <>
    <ChangeStatusBtn item={item} refreshItem={refreshItem} />
    {canEdit && <EditBtn id={item.id} size={buttonSizes.medium} />}
  </>
)

const getTabs = (issue, t, refreshItem = () => {}) => [
  {
    title: t('titles.comments'),
    content: (
      <Comments
        issue={issue}
        canAddNewComment={getCanAddNewComment(issue)}
        refreshItem={refreshItem}
      />
    )
  },
  { title: t('titles.history'), content: <History issue={issue} /> }
]

export const AuditSection = ({
  issue,
  t,
  itemTemplate: ItemTemplate = SidebarColumnSectionItem
}) => {
  const { audit } = issue

  /**
   * @param {string} date
   * @param {string} author
   * @returns {JSX.Element}
   */
  const getAuditValue = (date, author) => (
    <>
      {getFormattedDateWithTime(date, 'sv-SE')} <br />
      {author || '-'}
    </>
  )

  const auditCreateDate = getCreateDate(issue)

  return (
    <>
      <ItemTemplate
        label={t('common.labels.created')}
        value={getAuditValue(auditCreateDate, audit.creatorFullName)}
        className='issue-details__audit'
      />

      <ItemTemplate
        label={t('common.labels.lastUpdated')}
        value={getAuditValue(audit.modifiedDate, audit.modifierFullName)}
        className='issue-details__audit'
      />
    </>
  )
}

const AddressSection = ({
  issue,
  t,
  itemTemplate: ItemTemplate = MainColumnContentItem
}) => {
  const { street, number, zip, city } = issue.locationAddress

  return (
    <>
      <ItemTemplate label={t('issues.form.labels.street')} value={street} />

      <ItemTemplate label={t('issues.form.labels.number')} value={number} />

      <ItemTemplate
        label={t('issues.form.labels.zip')}
        value={formatZipCodeValue(zip)}
      />

      <ItemTemplate label={t('issues.form.labels.city')} value={city} />
    </>
  )
}

const DetailsMainColumn = ({ item, ctas: Ctas, t, ...props }) => (
  <MainColumnTemplate>
    <MainColumnHeader
      title={item.title}
      info={
        <>
          <label>{t('issues.form.labels.woId')}</label>{' '}
          {item.importedWorkOrderId || item.woId}
          <br />
          <label>{t('issues.form.labels.bastRefNo')}</label> {item.bastRefNo}
        </>
      }
      ctas={<Ctas item={item} {...props} />}
    />

    <MainColumnContent>
      <div className='issue-details__info-grid'>
        <MainColumnContentItem
          label={t('issues.form.labels.issueTypeId')}
          value={item.issueType.name}
        />

        <MainColumnContentItem
          label={t('issues.form.labels.priority')}
          value={getPriority(item.priority)?.text || item.priority}
        />

        <MainColumnContentItem
          label={t('issues.form.labels.customerId')}
          value={item.customer.name}
        />

        <MainColumnContentItem
          label={t('issues.form.labels.contractTypeId')}
          value={item.contractType.name}
        />
        {!!item.faultReason && (
          <MainColumnContentItem
            label={t('issues.form.labels.faultReasonId')}
            value={item.faultReason.name}
          />
        )}

        <AuditSection issue={item} t={t} itemTemplate={MainColumnContentItem} />

        <AddressSection
          issue={item}
          t={t}
          itemTemplate={MainColumnContentItem}
        />
      </div>

      <MainColumnContentItem
        label={t('issues.form.labels.description')}
        value={item.description}
      />

      <div className='issue-details__tabs'>
        <TabBar tabs={getTabs(item, t, props.refreshItem)} />
      </div>
    </MainColumnContent>
  </MainColumnTemplate>
)

/**
 * Dislays attachments in sections for both bast and bastKund systems
 * @param {Object} props
 * @param {AttachmentInfoUI[]} props.attachments
 * @param {TFunction} props.t
 * @returns {JSX.Element}
 */
export const AttachmentsListSection = ({ attachments, t }) => {
  const bastAttachments = getBastAttachments(attachments)
  const bastKundAttachements = getBastKundAttachments(attachments)

  return (
    <>
      {!!bastAttachments.length && (
        <SidebarColumnSectionItem
          label={`${systems.bast.label} ${getAttachmentsLabel(
            bastAttachments.length,
            t
          )}`}
          value={<AttachmentsList items={bastAttachments} />}
        />
      )}

      {!!bastKundAttachements.length && (
        <SidebarColumnSectionItem
          label={`${systems.bastKund.label} ${getAttachmentsLabel(
            bastKundAttachements.length,
            t
          )}`}
          value={<AttachmentsList items={bastKundAttachements} canDownload />}
        />
      )}
    </>
  )
}

const DetailsSidebarColumn = ({ item, t }) => {
  const { attachmentsInfo: attachments } = item
  const uiAttachments = formatAttachmentsForUi(attachments)

  return (
    <SidebarColumnTemplate>
      <SidebarColumnSection className='issue-details__badges'>
        <StatusBadge status={item.status} />

        <PriorityBadge priority={item.priority} />
      </SidebarColumnSection>

      <SidebarColumnSection>
        <AttachmentsListSection attachments={uiAttachments} t={t} />
      </SidebarColumnSection>
    </SidebarColumnTemplate>
  )
}

const DetailsContainer = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  const url = getUrl.issueById(id)
  const { loading, items: data, error } = useData(url, refresh)

  const Info = getNotification(loading, error, data, Loader)

  const canEdit = getCanEditIssue(data)

  return Info ? (
    Info
  ) : (
    <TwoColumnsTemplate className='issue-details'>
      <DetailsMainColumn
        item={data}
        ctas={DetailsCtas}
        canEdit={canEdit}
        refreshItem={toggleRefresh}
        t={t}
      />

      <DetailsSidebarColumn item={data} t={t} />
    </TwoColumnsTemplate>
  )
}

export default DetailsContainer

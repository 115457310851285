import * as Yup from 'yup'

import { getFormattedOptions } from '../../CommonForm'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .max(45, t('common.form.validation.maxLength', { count: 45 }))
      .required(t('customerTypes.form.validation.name.required')),
    contractTypeIds: Yup.array()
      .min(
        1,
        t('customerTypes.form.validation.contractTypeIds.minLength', {
          count: 1
        })
      )
      .required(t('customerTypes.form.validation.contractTypeIds.required'))
  })

export const formatValuesForApi = values => {
  const data = { ...values }

  data.contractTypeIds = values.contractTypeIds.map(item => item.value)

  return data
}

export const formatItemForForm = item => ({
  id: item?.id,
  name: item?.name,
  active: item?.active,
  contractTypeIds: getFormattedOptions(item?.contractTypes),
  shouldSendFaultReason: item?.shouldSendFaultReason
})

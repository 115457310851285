import React from 'react'

import { Select, getFormattedOptions } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { sortByParam } from '../../helpers/lists'
import { getActiveItems } from '../ContractTypes/helpers'

const FaultReasonPicker = ({
  name = 'faultReasonId',
  label,
  options = [],
  loading = false,
  isClearable = false,
  readOnly = false,
  disabled = false,
  ...props
}) => {
  return (
    <Select
      name={name}
      label={label}
      options={options}
      readOnly={readOnly}
      disabled={disabled}
      isClearable={isClearable}
      loading={loading}
      {...props}
    />
  )
}

const FaultReasonPickerContainer = ({ disabled, ...props }) => {
  const faultReasonsUrl = getUrl.faultReasons()
  const { items: faultReasons, loading } = useData(faultReasonsUrl)

  // FuaultReasonPicker is used for new issue and editted issue,
  // hence we need to supply different option source for cases where fault reason was deactivated
  const faultReasonsForPicker =
    disabled && !!faultReasons
      ? faultReasons
      : getActiveItems(faultReasons || [])
  const faultReasonsSorted = sortByParam(faultReasonsForPicker, 'asc', 'name')
  const faultReasonsOptions = getFormattedOptions(faultReasonsSorted || [])

  return (
    <FaultReasonPicker
      options={faultReasonsOptions}
      loading={loading}
      disabled={disabled}
      {...props}
    />
  )
}

export default FaultReasonPickerContainer
